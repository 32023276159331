<template>
  <div class="collection-tab">
    <div class="collection-tab-content">
      <StoryblokComponent
        v-for="item in blok.items"
        :key="item._uid"
        class="collection-tabs-item"
        :class="{ half: item.is_half }"
        :blok="item"
        :level="3"
        :size-hint="item.is_half ? 50 : 90"
      />
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  blok: {
    type: Object,
    required: true,
  },
});

const isDesktop = inject("isDesktop");
</script>

<style lang="scss" scoped>
.collection-tab {
  display: flex;
  flex-direction: column;
  gap: 0.63rem;
  min-height: 100vh;
  @include for-tablet-landscape-up {
    min-height: auto;
  }

  .minor {
    opacity: 0.7;
  }
}
.collection-tab-content {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 0.62rem;
}
// Override design for adding videos inside tab on page-generic
#page-generic-main .collection-tab-content {
  display: initial;
}
.collection-tabs-item {
  width: 100%;

  &.is-special {
    width: 100%;
  }

  @include for-tablet-portrait-up {
    &.half {
      width: calc(50% - 0.31rem);
      :deep(.media) {
        img {
          aspect-ratio: 1.4;
        }
      }
    }
    &.key-number {
      width: calc(50% - 0.31rem);
      &.full-width {
        width: 100%;
      }
    }
  }

  @include for-desktop-up {
    &.media-text-cta:not(.half) {
      :deep(.media),
      :deep(.collection-carousel img),
      :deep(.collection-carousel .youtube-player) {
        max-height: 70vh;
      }
      :deep(.media:not(.youtube-player)),
      :deep(.collection-carousel img),
      :deep(.collection-carousel .youtube-player) {
        aspect-ratio: 2/1 !important;
      }
    }
  }

  &.key-number {
    flex-direction: column;
    justify-content: center;
    gap: 1.5rem;
    align-items: flex-start;
  }
}
</style>
